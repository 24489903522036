import * as React from 'react';
import { SearchForm } from '@websky/core';
import { ThemeProvider } from '@websky/core/src/theme';
import theme from '../../theme/theme';
import '../../theme/variables.css';
import Head from '@websky/core/src/Head';
var SearchFormComponent = function () {
    return (React.createElement(ThemeProvider, { value: theme },
        React.createElement(Head, null),
        React.createElement(SearchForm, null)));
};
export default SearchFormComponent;
