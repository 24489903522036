import cn from 'classnames';
import OrderCard from './components/OrderCard/OrderCard.css';
import Location from './components/SearchForm/components/SearchForm/Segments/Segment/Location/Location.css';
import Contacts from './components/Contacts/Contacts.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import Checkout from './components/Checkout/Checkout.css';
import OrderPaidServiceCards from './components/OrderPaidServiceCards/OrderPaidServiceCards.css';
import Toolbar from './components/Toolbar/Toolbar.css';
import PaymentForm from './components/PaymentForm/PaymentForm.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import DesktopFlightRoute from './components/DesktopFlightRoute/DesktopFlightRoute.css';
import Results from './components/Results/Results.css';
import Stepbar from './components/Stepbar/Stepbar.css';
import Insurance from './components/Insurance/Insurance.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import FareGroupGrid from './components/FareGroup/components/FareGroupGrid/FareGroupGrid.css';
import WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import MobileFlight from './components/MobileFlight/MobileFlight.css';
import FareLock from './components/FareLock/FareLock.css';
import * as Button from './components/Button/Button.css';
import * as Switch from './components/Switch/Switch.css';
import Baggage from './components/Baggage/Baggage.css';
import Seats from './components/Seats/Seats.css';
import Meal from './components/Meal/Meal.css';
import MobileToolbar from './components/MobileToolbar/MobileToolbar.css';
import Modal from './components/Modal/Modal.css';
import SuggestionModalContent from './components/SuggestionModalContent/SuggestionModalContent.css';
import DesktopFlight from './components/DesktopFlight/DesktopFlight.css';
import FlightCard from './components/FlightCard/FlightCard.css';
import FullscreenDialog from './components/FullscreenDialog/FullscreenDialog.css';
import ModalHeading from './components/ModalHeading/ModalHeading.css';
import MobileSegmentInfo from './components/MobileSegmentInfo/MobileSegmentInfo.css';
import TransferInfo from './components/TransferInfo/TransferInfo.css';
import Checkin from './components/Checkin/Checkin.css';
import Input from './components/Input/Input.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import Segment from './components/Segment/Segment.css';
import MobileSegment from './components/MobileSegment/MobileSegment.css';
import SelectedPassenger from './components/SelectedPassenger/SelectedPassenger.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import StatusMessage from './components/StatusMessage/StatusMessage.css';
import RegisteredPassenger from './components/RegisteredPassenger/RegisteredPassenger.css';
import CheckinRules from './components/CheckinRules/CheckinRules.css';
import Refund from './components/Refund/Refund.css';
import Exchange from './components/Exchange/Exchange.css';
import CommonContent from './components/Exchange/CommonContent/CommonContent.css';
import Passenger from './components/Passenger/Passenger.css';
import Business from './components/CheckoutAdditionalService/components/Business/Business.css';
import LoginForm from './components/LoginPage/components/LoginForm/LoginForm/LoginForm.css';
import VipServiceSelect from './components/VipServiceSelect/components/VipServiceSelect.css';
import PriorityBoarding from './components/CheckoutAdditionalService/components/PriorityBoarding/PriorityBoarding.css';
import CheckoutPassengersToolbar from './components/CheckoutPassengersToolbar/CheckoutPassengersToolbar.css';
import ModalX from './components/ModalX/ModalX.css';
import ModalConfirm from './components/ModalConfirm/ModalConfirm.css';
import CheckDataModal from './components/CheckDataModal/CheckDataModal.css';
var theme = {
    CheckinRules: {
        CheckinRules: {
            rules__text: CheckinRules.webRegistration__conditions
        }
    },
    SearchForm: {
        SearchForm: {
            searchForm: SearchForm.searchForm,
            row: SearchForm.row
        },
        StartSearch: {
            startSearch: SearchForm.startSearch
        },
        Bottom: {
            bottom: SearchForm.bottom
        },
        Segment: {
            input: SearchForm.input,
            cell: SearchForm.cell,
            cell_date: SearchForm.cell_date,
            cell_location_arrival: SearchForm.cell_location_arrival
        },
        Controls: {
            controls: SearchForm.controls
        },
        Segments: {
            segments: SearchForm.segments
        },
        Value: {
            wrapper: SearchForm.valueWrapper,
            code: SearchForm.code,
            value: SearchForm.value
        },
        Location: {
            switcher: cn(SearchForm.switcher, Location.switcher),
            switcher_alternative: cn(SearchForm.switcher_alternative, Location.switcher_alternative),
            switcher_highlighted: SearchForm.switcher_highlighted,
            input: SearchForm.location__input
        },
        Datepicker: {
            dates: SearchForm.dates,
            dates__to: SearchForm.dates__to,
            dates__back: SearchForm.dates__back,
            dates_focused: SearchForm.dates_focused,
            returnDelete: SearchForm.returnDelete,
            placeholder: SearchForm.datesPlaceholder,
            dates__to_focused: SearchForm.dates__to_focused,
            dates__back_focused: SearchForm.dates__back_focused,
            dow: SearchForm.dow,
            alternative: SearchForm.alternative,
            alternative_dow: SearchForm.alternative_dow
        },
        Passengers: {
            selector: SearchForm.selector,
            passengers: SearchForm.passengers,
            arrowIcon: SearchForm.arrowIcon,
            title: SearchForm.passengerTitle,
            alternative: SearchForm.passengerAlternative,
            mobileTitle: SearchForm.passengersMobileTitle
        },
        PayByMiles: {
            switchBox: SearchForm.switchBox,
            switchWrapper: SearchForm.switchWrapper
        }
    },
    Passengers: {
        PassengerStyles: {
            increase: SearchForm.increase,
            decrease: SearchForm.decrease,
            counter: SearchForm.counter,
            counter_disabled: SearchForm.counter_disabled
        }
    },
    PassengerForm: {
        Header: {
            number: Checkout.passengerForm__header
        },
        Group: {
            container: Checkout.group__container,
            field_birthdate: Checkout.field_birthdate
        }
    },
    Button: {
        Button: {
            button_flat: SearchForm.button_flat,
            button: Button.button,
            button_large: SearchForm.button_large
        },
        ActionButton: {
            button: Button.action
        },
        BaseButton: {
            button: Baggage.button
        },
        WhiteButton: {
            button: Button.button_white
        }
    },
    SearchFormDatepicker: {
        DayStyles: {
            day_withPrice: SearchForm.day_withPrice,
            day_endHover_withPrice: SearchForm.day_endHover_withPrice,
            day_startHover_withprice: SearchForm.day_startHover_withprice,
            day_selected: SearchForm.day_selected,
            day: SearchForm.day,
            day_inPeriod: SearchForm.day_inPeriod,
            day_weekend: SearchForm.day_weekend,
            day_today: SearchForm.day_today,
            day__price: SearchForm.day__price
        },
        MonthStyles: {
            month: SearchForm.month
        },
        MonthsWrapperStyles: {
            nav_prev: SearchForm.nav_prev
        },
        SearchFormDatepickerStyles: {
            content: SearchForm.content,
            tabs: SearchForm.tabs,
            footer: SearchForm.footer,
            footer__buttons: SearchForm.footer__buttons,
            footer__done: SearchForm.footer__done,
            datepicker__disclaimer: SearchForm.datepicker__disclaimer,
            popup: SearchForm.popup,
            progress__root: SearchForm.progress__root,
            footer__clear: SearchForm.footer__clear
        },
        WeeklyHeaderStyles: {
            dayOfWeekName_withPrices: SearchForm.dayOfWeekName_withPrices
        }
    },
    Datepicker: {
        DayStyles: {
            day_notAvailable: SearchForm.day_notAvailable
        },
        MonthHeaderStyles: {
            monthHeader__select_month: SearchForm.monthHeader__monthHeaderSelectMonth,
            monthHeader__select_year: SearchForm.monthHeader__monthHeaderSelectYear
        }
    },
    LocationAutocomplete: {
        Group: {
            group__label: SearchForm.group__label
        },
        Option: {
            label: SearchForm.option__label
        },
        Autocomplete: {
            autocomplete: SearchForm.autocomplete
        }
    },
    DesktopFlightRoute: {
        DesktopFlightRouteStyles: {
            flight: DesktopFlightRoute.flight,
            selected: DesktopFlightRoute.selected
        },
        FlightPriceStyles: {
            button: DesktopFlightRoute.flightPrice,
            fare_family: DesktopFlightRoute.flightPrice__fareFamily,
            price__money: DesktopFlightRoute.flightPrice__priceMoney,
            price: DesktopFlightRoute.flightPrice__price,
            price_selected: DesktopFlightRoute.flightPrice__priceSelected
        },
        RouteArrowStyles: {
            flightNumber: DesktopFlightRoute.flightNumber
        },
        SegmentStyles: {
            flightInfo: DesktopFlightRoute.segment__flightInfo,
            flightInfo__date: DesktopFlightRoute.flightInfo__date,
            aircraft: DesktopFlightRoute.segment__aircraft
        }
    },
    MobileFlight: {
        FlightStyles: {
            price: MobileFlight.flightPrice,
            selectedFare__price: Checkout.selectedFare__price,
            selectedFare: Checkout.selectedFare
        }
    },
    Results: {
        Results: {
            inner: Results.inner,
            outer_bottom: Results.outer
        },
        Calendar: {
            legNumber: Results.legNumber,
            diagram: Results.diagram,
            tabs: Results.calendar_tabs
        },
        SelectedFares: {
            flight: Results.selectedFlights,
            title: Results.selectedFlights__title
        },
        FlightsList: {
            wrapper: Results.flightList
        },
        OtherDates: {
            container: Results.otherDates,
            text: Results.text
        }
    },
    MobileSelectedFare: {
        MobileSelectedFareStyles: {
            selectedFare: Results.mobileSelectedFares
        }
    },
    FareLock: {
        FareLock: {
            root: FareLock.wrapper,
            switch_block: FareLock.switchBlock,
            description_block: FareLock.description_block
        },
        DescriptionBlock: {
            title: FareLock.title,
            description: FareLock.description,
            icon: FareLock.icon
        }
    },
    Stepbar: {
        Stepbar: {
            stepbar: Stepbar.stepbar,
            inner: Stepbar.inner
        },
        Step: {
            step: Stepbar.step,
            step_active: Stepbar.active,
            step_disabled: Stepbar.disabled,
            arrow: Stepbar.arrow
        }
    },
    FareGroup: {
        FareGroup: {
            fareGroup: FareGroup.fareGroup,
            keyFeature: FareGroup.keyFeature,
            keyFeature_paid: FareGroup.keyFeature__paid,
            fareGroup_white: FareGroup.fareGroup_white,
            option: FareGroup.option,
            option_unavailable: FareGroup.option_unavailable,
            option_free: FareGroup.option_free,
            option__title: FareGroup.option_title,
            option__value: FareGroup.option_value,
            fareGroup_Business: FareGroup.fare_business,
            price: FareGroup.price
        },
        FareGroupGrid: {
            upgradeOffer: FareGroup.fareGroupGrid__upgradeOffer,
            recommended: FareGroup.fareGroup__recommend,
            paid: FareGroup.fareOption_paid,
            option: cn(FareGroup.fareOption, FareGroupGrid.option),
            small: FareGroupGrid.small,
            not_available: FareGroup.fareOption_unavailable,
            header: FareGroup.header
        }
    },
    CompareFares: {
        CompareFares: {
            cell: Results.compareFares__cell,
            image: Results.compareFares__image,
            content: Results.compareFares__content,
            tableRow: Results.compareFares__tableRow,
            title: Results.compareFares__title,
            cellContent: Results.compareFares__cellContent,
            fareHeadingItem: Results.compareFares__fareHeadingItem,
            fareHeadingItem_isKey: Results.compareFares__fareHeadingItemIsKey,
            slider: Results.compareFares__slider,
            groupTitle: Results.compareFares__groupTitle
        }
    },
    WeekCalendar: {
        DayStyles: {
            price: WeekCalendar.price,
            day_selected: WeekCalendar.active,
            day: WeekCalendar.day,
            date: WeekCalendar.date,
            date__info: WeekCalendar.date__info
        },
        WeekCalendarStyles: {
            dayWrapper: WeekCalendar.day__wrapper
        },
        SliderStyles: {
            arrow: WeekCalendar.arrow
        }
    },
    Passenger: {
        Switch: {
            container: Switch.container,
            element: Switch.element,
            element_active: Switch.element_active
        },
        PassengerStyles: {
            form__field: Passenger.form__field,
            form_citizenship: Passenger.form_citizenship
        }
    },
    OrderCard: {
        OrderCard: {
            container: OrderCard.orderCard__container,
            type_review: OrderCard.orderCard__typeReview,
            root: OrderCard.orderCard__root
        },
        OrderPreview: {
            root: OrderCard.orderPreview__root,
            city: OrderCard.orderPreview__city,
            main_city: OrderCard.orderPreview__mainCity,
            overlay: OrderCard.orderPreview__overlay
        },
        OrderBooking: {
            orderCode: OrderCard.orderBooking__orderCode,
            security_code: OrderCard.orderBooking__securityCode,
            price: OrderCard.orderBooking__price,
            download: OrderCard.orderBooking__download,
            type_review: OrderCard.orderBooking__typeReview,
            root: OrderCard.orderBooking__root
        },
        CommonItem: {
            root: OrderCard.commonItem__root,
            icon: OrderCard.commonItem__icon
        },
        PaymentStatus: {
            root: OrderCard.paymentStatus__root,
            icon: OrderCard.paymentStatus__icon
        },
        OrderStatus: {
            root: OrderCard.orderStatus__root,
            type_review: OrderCard.orderStatus__typeReview,
            confirmed: OrderCard.orderStatus__confirmed,
            icon: OrderCard.orderStatus__icon
        },
        OrderPassengers: {
            root: OrderCard.orderPassengers__root,
            control: OrderCard.orderPassengers__control
        },
        OrderTickets: {
            link: OrderCard.orderTickets__link
        },
        OrderChange: {
            button: OrderCard.orderChange__button
        },
        OrderCardControls: {
            button: OrderCard.orderCardControls__button,
            button_checkin: OrderCard.orderCardControls__buttonCheckin
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalService: {
            service: CheckoutAdditionalService.additionalService__service,
            service_active: CheckoutAdditionalService.additionalService__serviceActive,
            icon: CheckoutAdditionalService.additionalService__icon,
            header: CheckoutAdditionalService.additionalService__header,
            content: CheckoutAdditionalService.additionalService__content,
            add: CheckoutAdditionalService.additionalService__add,
            buttons: CheckoutAdditionalService.additionalService__buttons,
            main: CheckoutAdditionalService.additionalService__main,
            header__price: CheckoutAdditionalService.additionalService__headerPrice,
            buttons__price: CheckoutAdditionalService.additionalService__buttonsPrice,
            header__text: CommonContent.header__text,
            content: cn(CommonContent.content, CheckoutAdditionalService.content)
        },
        CheckoutAdditionalServiceMeal: {
            meal: cn(CheckoutAdditionalService.additionalService__image, CheckoutAdditionalService.additionalService__meal)
        },
        CheckoutAdditionalServiceBaggage: {
            baggage: cn(CheckoutAdditionalService.additionalService__image, CheckoutAdditionalService.additionalService__baggage)
        },
        Seats: {
            seats: cn(CheckoutAdditionalService.additionalService__image, CheckoutAdditionalService.additionalService__seats)
        },
        Business: {
            business: cn(CheckoutAdditionalService.additionalService__image, CheckoutAdditionalService.additionalService__business),
            header: Business.header
        },
        Aeroexpress: {
            aeroexpress: cn(CheckoutAdditionalService.additionalService__image, CheckoutAdditionalService.additionalService__aeroexpress)
        },
        Loyalty: {
            loyalty: CheckoutAdditionalService.additionalService__loyalty
        },
        Insurance: {
            insurance: cn(CheckoutAdditionalService.additionalService__image, CheckoutAdditionalService.additionalService__insurance)
        },
        PriorityBoarding: {
            priority: PriorityBoarding.priority,
            priorityBoardingModal: PriorityBoarding.priorityBoardingModal,
            header: PriorityBoarding.header
        }
    },
    Checkout: {
        OrderPaidServiceCards: {
            baggagePage__baggage: OrderPaidServiceCards.baggagePage,
            meal__dialogContent: OrderPaidServiceCards.meal__dialogContent
        },
        CheckoutStyles: {
            container: Checkout.checkout__container,
            checkoutServices: Checkout.checkout__services,
            summary__header: Checkout.checkout__summaryHeader,
            summary: Checkout.checkout__summary,
            flight: Checkout.checkout__flight,
            desktopFlight__flight: Checkout.checkout__flight,
            gdsTotalPrice: Baggage.gdsTotalPrice
        },
        ThanksBanner: {
            wrapper: Checkout.thanksBanner__wrapper,
            title: Checkout.thanksBanner__title
        },
        PassengersToolbar: {
            label: CheckoutPassengersToolbar.label
        },
        CheckDataModal: {
            paper: CheckDataModal.paper
        }
    },
    ModalConfirm: {
        ModalConfirm: {
            header: ModalConfirm.header,
            title: ModalConfirm.title,
            buttons: ModalConfirm.buttons,
            content: ModalConfirm.content,
            footer: ModalConfirm.footer,
            cancelButton: ModalConfirm.cancelButton,
            confirmButton: ModalConfirm.confirmButton,
            closeButton: ModalConfirm.closeButton
        }
    },
    BaseComponents: {
        ModalX: {
            closeButton: ModalX.closeButton
        }
    },
    PaymentForm: {
        PaymentForm: {
            wrapper: PaymentForm.payment__wrapper,
            details: PaymentForm.payment__details,
            details__button: PaymentForm.payment__details__button,
            content: PaymentForm.payment__content,
            content__title: PaymentForm.payment__content__title,
            oneMethod: PaymentForm.payment__oneMethod,
            cards: PaymentForm.cards,
            card: PaymentForm.card,
            gateways: PaymentForm.gateways,
            gateways_hidden: PaymentForm.gateways_hidden
        },
        Summary: {
            group: PaymentForm.payment__group,
            item: PaymentForm.payment__item
        },
        Card: {
            iframe: PaymentForm.iframe
        },
        Gateway: {
            header__arrow: PaymentForm.header__arrow,
            header: PaymentForm.header,
            selector: PaymentForm.selector,
            arrow: PaymentForm.arrow
        }
    },
    PaymentTimeLimit: {
        PaymentTimeLimit: {
            wrapper: PaymentForm.payment__timeLimit
        }
    },
    IncludedBaggage: {
        IncludedBaggage: {
            header: Baggage.header,
            root: Baggage.root,
            included_block: Baggage.included_block
        }
    },
    AdditionalBaggage: {
        AdditionalBaggage: {
            root: Baggage.root,
            body: Baggage.body,
            header: Baggage.header
        },
        PromoMessage: {
            root: Baggage.promo_message
        }
    },
    Baggage: {
        BaggageIcon: {
            hand_luggage_excess: Baggage.handLuggageExcess,
            carry_on: Baggage.carryOn,
            wrapper: Baggage.iconWrapper,
            size: cn(Baggage.iconSize),
            root: Baggage.iconRoot
        },
        Baggage: {
            name: Baggage.name,
            size: Baggage.size
        }
    },
    BaggageList: {
        BaggageCount: {
            root: Baggage.counter
        },
        BaggageDirection: {
            root: Baggage.direction
        },
        BaggageList: {
            item: Baggage.item,
            root: Baggage.baggageList
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            control: Baggage.control,
            price: Baggage.price,
            control__edit: Baggage.control__edit
        }
    },
    BaggagePage: {
        BaggagePage: {
            items: Baggage.items,
            newBaggage: Baggage.newBaggage
        }
    },
    BaggageCounter: {
        BaggageCounter: {
            minus: Baggage.counterButton,
            plus: Baggage.counterButton,
            disabled: Baggage.counterButton_disabled
        }
    },
    LoginPage: {
        LoginForm: {
            row: LoginForm.row
        }
    },
    BaggageCard: {
        BaggageCard: {
            icon: Baggage.cardIcon,
            wrapper: Baggage.cardWrapper,
            button: Baggage.cardButton,
            price: Baggage.price
        }
    },
    UpgradeBaggageDialog: {
        BaggagePassenger: {
            name: Baggage.passengerName,
            checkbox_root: Baggage.checkbox_root,
            checkbox_rootChecked: Baggage.checkbox_rootChecked
        },
        BaggageFooter: {
            confirm_btn: Baggage.confirm_btn,
            clear: Baggage.clear
        },
        BaggageDialogHeader: {
            perFlight: Baggage.perFlight
        },
        BaggageSegment: {
            root: Baggage.segment,
            name: Baggage.flightName
        },
        MobileBaggageSelector: {
            footer: Baggage.mobileSelector,
            description: Baggage.mobileSelector__description
        }
    },
    Toolbar: {
        Toolbar: {
            button: Baggage.toolButton,
            price: Baggage.toolPrice,
            backButton: cn(Baggage.backButton, Toolbar.toolbar__backButton),
            backButton__wrapper: Baggage.backButton__wrapper,
            toolbar: Toolbar.toolbar__container,
            inner: Toolbar.toolbar__inner,
            actions: Toolbar.toolbar__actions
        }
    },
    Switch: {
        SwitchV2: {
            primary: Baggage.switcherPrimary,
            active: Baggage.switcherPrimary_active
        }
    },
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            baggageBanner: Baggage.baggageBanner,
            extrasBanner: Checkin.extrasBanner
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            button_back: Baggage.mobileButtonBack,
            button_continue: Baggage.mobileButtonСontinue,
            wrapper: MobileToolbar.mobileToolbar__wrapper,
            button: MobileToolbar.mobileToolbar__button
        }
    },
    Insurance: {
        InsuranceStyles: {
            dialog: Insurance.insurance__dialog
        },
        MobileScreenStyles: {
            header: Checkin.insurance__header
        },
        InsuranceCardStyles: {
            insurance__header: Insurance.insuranceCard__header,
            insurance__button: cn(Checkin.insurance__button, Insurance.insuranceCard__button),
            insurance__button_selected: Insurance.insuranceCard__button_selected
        }
    },
    ResendTicketsForm: {
        ResendTicketsForm: {
            close: Checkin.resendTicket__close,
            wrapper: Checkin.resendTicket__wrapper
        }
    },
    BaggageService: {
        BaggageServiceStyles: {
            baggage_full: Baggage.baggage_full,
            baggage__icon_notSelected: Baggage.baggage__icon_notSelected,
            baggage: Baggage.baggage,
            carryon: Baggage.carryon,
            service__price: Baggage.service__price,
            service__values: Baggage.service__values,
            overweight__disabled: Baggage.overweight__disabled,
            add__button: Baggage.add__button
        }
    },
    SeatMap: {
        SeatStyles: {
            seat: Seats.seat,
            seat_occupied: Seats.seat_occupied,
            seat_preferred: Seats.seat_preferred,
            seat_business: Seats.seat_business,
            seat_unavailable: Seats.seat_unavailable,
            seat_confirmed: Seats.seat_confirmed,
            seat_recommend: Seats.seat_recommend,
            seat_recommendCurrent: Seats.seat_recommendCurrent,
            number: Seats.number,
            seat_free: Seats.seat_free
        },
        SeatsInfoStyles: {
            image: Seats.seatsInfo__image,
            title: Seats.title,
            seat_economy: Seats.seat_economy,
            seat_preferred: Seats.seat_preferred,
            seat_business: Seats.seat_business,
            classInfo: Seats.classInfo,
            seat: Seats.seatInfo,
            price__wrapper: Seats.price__wrapper
        },
        SeatTooltipStyles: {
            tooltip: Seats.tooltip
        },
        Passenger: {
            item: Seats.item,
            item_recommend: Seats.item_recommend,
            item_active: Seats.item_active,
            passengerName: Seats.passengerName,
            money: Seats.money,
            select: Seats.select,
            payload: Seats.payload,
            avatar: Seats.avatar
        },
        PassengersStyles: {
            recommend__wrapper: Seats.recommend__wrapper,
            passengers__button: Seats.passengers__button,
            passengers__button_clear: Seats.passengers__button_clear,
            recommend: Seats.recommend
        },
        AirplaneStyles: {
            airplane: Seats.airplane,
            airplane__name: Seats.airplane__name
        },
        AirplaneInfoStyles: {
            sunInfo: Seats.sunInfo
        },
        Controls: {
            button: Seats.controlsButton
        },
        SeatMapDisclaimerStyles: {
            disclaimer__wrp: Seats.disclaimer__wrp
        }
    },
    Disclaimer: {
        DisclaimerStyles: {
            disclaimer__icon: Seats.disclaimer__icon
        }
    },
    SegmentTabs: {
        SegmentTab: {
            segment: Seats.segment,
            checked: Seats.checked
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: cn(Seats.amenities__item, DesktopFlightRoute.amenities__item),
            amenities__item_unavailable: DesktopFlightRoute.unavailable
        }
    },
    Meal: {
        Meal: {
            meal: Meal.meal,
            name: Meal.name,
            price: Meal.price,
            image: Meal.image,
            header: Meal.header,
            count: Meal.count
        },
        Details: {
            image: Meal.detailsImage,
            wrapper: Meal.detailsWrapper,
            trigger: Meal.detailsTrigger,
            passenger: Meal.passenger,
            total: Meal.total,
            totalText: Meal.totalText,
            clear: Meal.clear,
            addForAll: Meal.clear
        }
    },
    MealService: {
        CategoriesStyles: {
            wrapper: Meal.categoryWrapper
        }
    },
    CloseIcon: {
        CloseIcon: {
            wrapper: Meal.closeIcon
        }
    },
    Counter: {
        Counter: {
            button: Meal.countButton,
            button_disabled: Meal.countButton_disabled
        }
    },
    Modal: {
        Modal: {
            modal: Modal.modal__container,
            closeIcon: Modal.modal__closeIcon
        }
    },
    Contacts: {
        ContactsStyles: {
            footer__inner: Contacts.inner
        }
    },
    SuggestionModalContent: {
        SuggestionModalContent: {
            modal__button: SuggestionModalContent.suggestionModal__button,
            modal__button_decline: SuggestionModalContent.suggestionModal__buttonDecline,
            modal__text: SuggestionModalContent.suggestionModal__text
        }
    },
    DesktopFlight: {
        ModalContent: {
            content: DesktopFlight.modalContent__content,
            compareFares__paper: DesktopFlight.modalContent__compareFaresPaper
        },
        Location: {
            airport: DesktopFlight.location__airport
        },
        FlightInfoSegment: {
            flight: DesktopFlight.flightInfoSegment__flight,
            isMobile: DesktopFlight.flightInfoSegment__isMobile,
            locations: DesktopFlight.flightInfoSegment__locations
        }
    },
    FlightCard: {
        FlightCard: {
            info: FlightCard.info,
            aircraft__name: FlightCard.flightCard__aircraftName
        }
    },
    FullscreenDialog: {
        FullscreenDialog: {
            close__icon: FullscreenDialog.fullscreenDialog__closeIcon
        }
    },
    ModalHeading: {
        ModalHeading: {
            heading: ModalHeading.heading
        }
    },
    MobileSegmentInfo: {
        MobileSegmentFlat: {
            aircraft: MobileSegmentInfo.aircraft,
            image: MobileSegmentInfo.image,
            totalDuration: MobileSegmentInfo.segmentFlat__totalDuration
        }
    },
    TransferInfo: {
        TransferInfo: {
            transfer: TransferInfo.transfer,
            warning: TransferInfo.warning
        }
    },
    Checkin: {
        Inital: {
            wrapper: Checkin.initial__wrapper,
            form: Checkin.initial__form
        },
        Passengers: {
            wrapper: Checkin.passengers__wrapper,
            title: Checkin.passengers__title,
            segments: Checkin.passengers__segments,
            toolbar: Checkin.passengers__toolbar,
            toolbar__button: Checkin.passengers__toolbarButton,
            passengers: Checkin.passengers__passengers,
            checkinCancelModal__content: Checkin.checkinCancelModal__content
        },
        Checkin: {
            servicePrice: Checkin.checkin__servicePrice
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            title: RetrieveBooking.retrieveBooking__title,
            title__icon: RetrieveBooking.retrieveBooking__titleIcon,
            form__row: RetrieveBooking.retrieveBooking__formRow,
            button: RetrieveBooking.retrieveBooking__button,
            button_disabled: RetrieveBooking.retrieveBooking__buttonDisabled
        }
    },
    StatusMessage: {
        StatusMessage: {
            statusMessage: StatusMessage.statusMessage__wrapper,
            statusMessage_success: StatusMessage.statusMessage__success,
            statusMessage__title: StatusMessage.statusMessage__title,
            statusMessage__text: StatusMessage.statusMessage__text
        }
    },
    Segment: {
        SegmentCommon: {
            wrapper: Segment.segmentCommon__wrapper,
            wrapper_business: Segment.segmentCommon__wrapperBusiness,
            header: Segment.segmentCommon__header,
            city__weather: Segment.segmentCommon__cityWeather,
            flightInfo__item: Segment.segmentCommon__flightInfoItem,
            flightInfo__title: Segment.segmentCommon__flightInfoTitle,
            flightInfo__value: Segment.segmentCommon__flightInfoValue,
            city__airport: Segment.segmentCommon__cityAirport,
            city__info: Segment.segmentCommon__cityInfo,
            city__iata: Segment.segmentCommon__cityIata,
            city__time: Segment.segmentCommon__cityTime,
            plane__icon: Segment.segmentCommon__planeIcon,
            airline__name: Segment.segmentCommon__airlineName,
            footer__item: Segment.segmentCommon__footerItem,
            footer__itemTitle: Segment.segmentCommon__footerItemTitle,
            footer__itemValue: Segment.segmentCommon__footerItemValue
        }
    },
    RegisteredPassenger: {
        RegisteredPassenger: {
            wrapper: RegisteredPassenger.registeredPassenger__wrapper,
            header: RegisteredPassenger.registeredPassenger__header,
            passenger: RegisteredPassenger.registeredPassenger__passenger,
            seats: RegisteredPassenger.registeredPassenger__seats,
            seats__icon: RegisteredPassenger.registeredPassenger__seatsIcon,
            footerActions: RegisteredPassenger.registeredPassenger__footerActions,
            footerActions__cancel: RegisteredPassenger.registeredPassenger__footerActionsCancel,
            actions__buttons: RegisteredPassenger.registeredPassenger__actionsButtons,
            appleWallet: RegisteredPassenger.registeredPassenger__appleWaller,
            downloadButton: RegisteredPassenger.registeredPassenger__downloadButton,
            actions__barcode: RegisteredPassenger.registeredPassenger__actionsBarcode
        },
        SendToEmail: {
            text: RegisteredPassenger.sendToEmail__text
        }
    },
    Input: {
        Input: {
            real_input: Input.real_input,
            input: Input.input,
            textField__root: Input.textField__root,
            label_error: Input.label_error,
            textField: Input.textField,
            input_focused: Input.input_focused,
            disabled: Input.disabled,
            input_filled: Input.input_filled
        }
    },
    MobileSegment: {
        MobileSegment: {
            wrapper: MobileSegment.mobileSegment__wrapper,
            flightInfo: MobileSegment.mobileSegment__flightInfo,
            flightInfo__iatas: MobileSegment.mobileSegment__flightInfoIatas,
            flightInfo__weather: MobileSegment.mobileSegment__flightInfoWeather,
            flightInfo__dates: MobileSegment.mobileSegment__flightInfoDates,
            flightInfo__cities: MobileSegment.mobileSegment__flightInfoCities,
            flightInfo__time: MobileSegment.mobileSegment__flightInfoTime,
            arrivalInfo: MobileSegment.mobileSegment__arrivalInfo,
            fareGroup: MobileSegment.mobileSegment__fareGroup,
            fareGroup__img: MobileSegment.mobileSegment__fareGroupImg,
            planeIcon: MobileSegment.mobileSegment__planeInfo
        }
    },
    SelectedPassenger: {
        SelectedPassenger: {
            wrapper: SelectedPassenger.selectedPassenger__wrapper,
            passenger: SelectedPassenger.selectedPassenger__passenger,
            passenger__icon: SelectedPassenger.selectedPassenger__passengerIcon,
            passenger__name: SelectedPassenger.selectedPassenger__passengerName,
            passengerInfo: SelectedPassenger.selectedPassenger__passengerInfo,
            passengerInfo__item: SelectedPassenger.selectedPassenger__passengerInfoItem,
            passengerInfo__field: SelectedPassenger.selectedPassenger__passengerInfoField
        }
    },
    AddPassenger: {
        AddPassenger: {
            wrapper: AddPassenger.addPassenger__wrapper,
            form__wrapper: AddPassenger.addPassenger__formWrapper,
            form: AddPassenger.addPassenger__form,
            addButton: AddPassenger.addPassenger__addButton,
            addButton__icon: AddPassenger.addPassenger__addButtonIcon,
            submitButton: AddPassenger.addPassenger__submitButton,
            passengerIcon: AddPassenger.addPassenger__passengerIcon,
            form__input: AddPassenger.addPassenger__formInput
        }
    },
    ServiceRecommendation: {
        Base: {
            wrapper: Checkin.serviceRecommendation
        }
    },
    PaymentResult: {
        PaymentResult: {
            header: Checkin.payment__header,
            wrapper: Checkin.payment__wrapper
        }
    },
    Refund: {
        Breadcrumbs: {
            breadcrumb: Refund.breadcrumb
        },
        StepTitle: {
            icon: Refund.stepTitle_icon,
            text: Refund.stepTitle_text
        },
        ReasonForm: {
            groupTitle: Refund.groupTitle,
            reason: Refund.reason,
            active: Refund.reason_active
        },
        Reason: {
            label: Refund.reason_label,
            active: Refund.reason_active
        },
        Passenger: {
            label: Refund.passenger_label,
            passenger: Refund.passenger,
            active: Refund.passenger_active
        },
        Wrapper: {
            title: Refund.wrapper_title,
            close: Exchange.closeIcon
        },
        RequestForm: {
            formLabel: Refund.formLabel
        },
        Ticket: {
            title: Refund.ticket_title
        },
        Price: {
            total: Refund.price_total
        },
        RefundInfoModal: {
            button: Refund.refundInfoModal__button,
            no: Refund.refundInfoModal__button_no
        },
        Status: {
            waiting: Refund.status_waiting,
            icon: Refund.status_icon
        }
    },
    Exchange: {
        CommonSelect: {
            button: Exchange.button,
            header: Exchange.header
        },
        CreateRequest: {
            header: Exchange.header,
            submit: Exchange.createRequest_submit
        },
        SelectedFlights: {
            total: Exchange.total,
            charge: Exchange.charge,
            header__name: Exchange.header__name
        },
        Choice: {
            labelInner: Exchange.labelInner,
            label: Exchange.label,
            label_selected: Exchange.label_selected
        },
        Chip: {
            chip: Exchange.chip
        },
        Inner: {
            header__text: Exchange.header__text,
            closeIcon: Exchange.closeIcon
        },
        CommonContent: {
            serviceCard: CommonContent.serviceCard,
            name: CommonContent.name,
            passengerName: CommonContent.passengerName,
            price: CommonContent.price,
            totalRefund: CommonContent.totalRefund,
            serviceCard_meal: CommonContent.serviceCard_meal,
            serviceCard_seats: CommonContent.serviceCard_seats,
            serviceCard_baggage: CommonContent.serviceCard_baggage
        },
        SelectedServices: {
            services_exchange: CommonContent.services_exchange
        }
    },
    VipServiceSelect: {
        VipServiceSelect: {
            cancel: VipServiceSelect.cancel
        }
    },
    AdditionalServiceWrapper: {
        AdditionalServiceWrapper: {
            header: CheckoutAdditionalService.additionalServiceWrapper__header
        }
    }
};
export default theme;
